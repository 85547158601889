.menu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.left-component-wrapper {
  width: 40%;
  height: 100%;
  border-right: 1px solid rgb(235, 237, 240);
}

.right-component-wrapper {
  width: 60%;
  height: 100%;
}

.label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-tabs {
  height: 100%;
  width: 100%;
}

.ant-tabs-content {
  height: 100%;
  width: 100%;
}
