.editing-component-top-container {
  border-bottom: 1px solid #ffff;
  height: 8%;
  padding-top: 20px;
  text-align: center;
}

.editing-component-bottom-container {
  height: 92%;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.editing-component-label {
  margin-top: 20px;
}

.add-item-button {
  margin-right: 10px;
}
