.menu-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.sku-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.modifier-card {
  width: 400px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
