.home-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-content-wrapper {
  height: 100%;
  width: 100%;
  padding: 40px;
  overflow: auto;
}
