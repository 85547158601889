.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  width: 20%;
  padding: 24px;
  min-width:300px;
}

.login-logo {
  display: flex;
  height: 100px;
  width: 100px;
}

.login-btn {
  margin-top: 20px;
}
