.modifier-editing-component-bottom-container {
  height: 92%;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.add-modifier-item-button {
  margin-right: 10px;
}
