.sku-modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-wrapper {
  width: 40%;
}

#sku-modal-search-input {
  width: 90%;
  margin-right: 10px;
}

.modal-content {
  border: 2px solid rgb(235, 237, 240);
  height: 500px;
  padding: 20px;
  overflow: auto;
}

.sku-card {
  width: 300px;
}

.currently-selected-item {
  width: 40%;
}
