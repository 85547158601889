@import "~antd/dist/antd.less";

.App {
  text-align: center;
  overflow: hidden;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.main-header {
  padding: 0;
  height: 55px;
  border: 1px solid rgb(235, 237, 240);
}

.main-footer {
  padding: 0;
  height: 55px;
  border: 1px solid rgb(235, 237, 240);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.layout {
  height: 100%;
}

.content {
  height: 100%;
}

@layout-body-background: #ffffff;@layout-header-background: #ffffff;