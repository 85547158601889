.item-editing-component-bottom-container {
  height: 92%;
  overflow: auto;
  padding-left: 10px;
  padding-right: 12px;
  padding-bottom: 10px;
}

.image-row {
  margin-bottom: 20px;
}

.image-column {
  text-align: left;
}

#image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  max-width: 100%;
  height: 150px;
}

#item-image {
  width: 100%;
  max-width: 170px;
  height: auto;
}

#label-upload-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  max-width: 40%;
}

#image-label {
  margin: 0;
}

#progress-bar {
  margin-left: 10px;
  margin-right: 10px;
}
