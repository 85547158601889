.target-category-picker-wrapper {
  border: 1px solid rgb(235, 237, 240);
  padding-left: 10px;
  width: auto;
  height: auto;
}

.target-category-picker-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.target-check-all {
  text-align: left;
}
