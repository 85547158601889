.menu-items-table-wrapper {
  border-top: 1px solid rgb(235, 237, 240);
  height: auto;
  width: 100%;
  overflow: auto;
}

.menu-items-table {
  height: auto;
}

.menu-item-table-label {
  width: 20%;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.menu-item-search-div {
  display: flex;
  align-items: flex-start;
}

#menu-item-search-input {
  width: 70%;
}

.ant-table-row.ant-table-row-level-0.highlighted-row td.ant-table-cell {
  background-color: #1890ff;
  color: #ffff;
}
