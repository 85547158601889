#modifier-button {
  padding: 0px;
  margin-bottom: 20px;
  margin-right: 10px;
}

#optional {
  color: #bfbfbf;
}

.message-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
}

.remove-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}

.title-div {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}

.min-max-div {
  display: flex;
  height: 50px;
  padding-left: 10px;
}

.table-div {
  height: 500px;
  padding-top: 10px;
}

.list {
  list-style-type: disc;
}
