.home-page-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 8%;
  padding-right: 10px;
  border-top: 1px solid rgb(235, 237, 240);
}
