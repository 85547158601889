.site-page-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}

.header-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.back-arrow {
  height: fit-content;
  margin-left: 20px;
}

.header-title {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 55px;
}

.delete-menu-button {
  margin-right: 10px;
}

.publish-button {
  margin-right: 40px;
}

.search-div {
  display: flex;
  align-items: center;
}

.left-div {
  display: flex;
  align-items: center;
  height: 55px;
  width: 80%;
}

.right-div {
  display: flex;
  align-items: center;
  height: 55px;
}

.back-div {
  display: flex;
  align-items: center;
  height: 55px;
  width: 10%;
}

.menu-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 90%;
}

.brand-logo-div {
  display: flex;
  align-items: center;
  height: 55px;
  margin-right: 20px;
}

.home-title-div {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 40px;
  height: 55px;
  width: 100%;
}

.menu-title-div {
  display: flex;
  align-items: center;
  padding-top: 10px;
  height: 55px;
  width: auto;
}

.hours-div {
  margin-left: 20px;
}

.tag-div {
  display: flex;
  align-items: center;
  width: auto;
  height: 55px;
  margin-left: 20px;
}

.brand-logo {
  border: 1px solid rgb(235, 237, 240);
}

.header-logo {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100px;
}

.skeleton-avatar-home {
  margin: 20px 30px 0px 30px;
}

.skeleton-input-home {
  margin-top: 10px;
}

.skeleton-avatar {
  margin-top: 20px;
}

.skeleton-input {
  margin-top: 7px;
}

.skeleton-buttons {
  margin-top: 10px;
}

.home-toggle-button {
  margin-left: 20px;
}

.profile {
  display: flex;
}
