.internalPromos-table-wrapper {
  height: auto;
  width: 100%;
  overflow: auto;
  border-top: 1px solid rgb(235, 237, 240);
}

.internalPromos-table {
  height: auto;
}

.label {
  width: 96%;
  text-align: left;
  margin-left: 10px;
}

.ant-table-row.ant-table-row-level-0.highlighted-row td.ant-table-cell {
  background-color: #1890ff;
  color: #ffff;
}