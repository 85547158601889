.target-editing-component-bottom-container {
  height: 92%;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.hours-col {
  text-align: left;
}

.pannel-header {
  text-align: left;
}

.pannel-header-text {
  width: 96%;
  text-align: left;
  font-size: 1.5em;
  font-weight: 500;
}

.day-col {
  text-align: left;
  width: 80px;
}

.time-picker-col {
  margin-left: 20px;
}

#add-button {
  background-color: #52c41a;
  border: none;
  margin-left: 10px;
  margin-top: 5px;
}

#remove-button {
  border: none;
  margin-left: 10px;
  margin-top: 5px;
}

#plus-outlined {
  color: #ffff;
}

#minus-outlined {
  color: #ffff;
}